import React from "react";
import classNames from "classnames";
import Form from "@molecules/Form";
import { Container, Icon, Text } from "@atoms";
import useDefaultsData from "@staticQueries/DefaultsQuery";

const NewsletterSignup = ({ padding }) => {
  const { newsletter } = useDefaultsData();
  const { heading, descriptor, formId } = newsletter;
  return (
    <section
      className={classNames("relative bg-white pb-5 sm:pb-10", {
        "pt-10 sm:pt-20": padding,
      })}
    >
      <div className="absolute left-0 right-0 bottom-0 z-0 h-[50%] bg-black" />
      <Container>
        <div className="relative z-10 flex gap-6 overflow-hidden rounded-lg bg-teal p-4 sm:p-6 md:p-8 lg:p-12 xl:p-16">
          <div className="relative z-10 flex w-full flex-shrink-0 flex-col gap-6 sm:w-3/5">
            <Text variant="h2">{heading}</Text>
            <Text variant="xl">{descriptor}</Text>
            <Form
              formId={formId}
              condensed
              dark={false}
              requiredOnly
              successMessage="Thank you for subscribing to our newsletter."
            />
          </div>
          <div className="absolute -bottom-2 -right-2 z-0 w-[12rem] sm:w-[16rem]">
            <Icon name="watermark" className="h-auto w-full" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default NewsletterSignup;
